<template>
  <div class="subpage page-about">
    <!-- <div class="page-banner">
      <div class="banner-text">
       
        <h2>미륵사 소개</h2>
        <p class="desc">
          남녀노소 누구나 언제 어디서나 <br />
          간편하게 즐기는 미륵사에 오신걸 환영합니다.
        </p>
      </div>
    </div> -->
    <div class="about-title">
      <img src="images/about_title.png" height="80%" />
    </div>

    <div class="about-wrap">
      <section class="about-content">
        <div class="container">
          <div class="about2">
            <div class="about2-image">
              <img src="images/about_img_01.png" width="300" />
            </div>
            <div class="about2-text">
              <img src="images/about_title_01.png" width="250" />
              <p>
                저희 미륵사에서
              </p>
              <p>
                부처님의 말씀을 들을 수 있는 법회 스스로를 수행하는 예불에 참여하고 기도를 드리고 공덕을 쌓아보세요
              </p>
            </div>
          </div>

          <div class="about2">
            <div class="about2-image">
              <img src="images/about_img_02.png" width="300" />
            </div>
            <div class="about2-text">
              <img src="images/about_title_02.png" width="250" />
              <p>
                실제 스님의 좋은 말씀을 온라인을 통해 들을 수 있는 실시간 스트리밍 서비스와 영상을 만나보세요!!
              </p>
            </div>
          </div>

          <div class="about2">
            <div class="about2-image">
              <img src="images/about_img_03.png" width="250" />
            </div>
            <div class="about2-text">
              <img src="images/about_title_03.png" width="250" />
              <p>
                오늘 나의 운세는 어떨까요?
              </p>
              <p>
                운세/사주, 작명, 궁합, 관상, 택일 등
              </p>
              <p>
                총 100가지의 질 높은 서비스를 제공합니다.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
}
</script>
<style lang="scss" scoped>
.about-title {
  text-align: center;
  img {
    max-width: 95%;
    margin-top: 4rem;
  }
}
.about2 {
  max-width: 900px;
  width: 100%;
  margin: 50px auto 80px;
  display: flex;
  flex-wrap: wrap;

  .about2-image {
    width: 400px;
    text-align: center;
    img {
      margin-bottom: 20px;
    }
  }
  .about2-text {
    max-width: 500px;
    text-align: left;
    padding-top: 50px;
    img {
      font-size: 1.2rem;
      // text-decoration: underline;
      margin-bottom: 20px;
    }
    p {
      line-height: 1.6;
      font-size: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .about2 {
    .about2-text {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
